import styled from 'styled-components';

export const HeaderSection = styled.div`
    border: none;
    text-shadow: none;
    box-shadow: rgb(204, 204, 204) 0px 3px 7px -4px;
    transition: all 0.3s ease 0s;
    opacity: 1!important;
    box-sizing: border-box;
    background: white;
    padding: 0px 0.36rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.5rem;
    color: rgb(149, 148, 148);
    z-index: 1000;
    position: fixed!important;
    height: 50px; 
    width: 100%;
    max-width: 970px;

    position: absolute;
    top: 0px;

    .title {
        font-weight: bold;
        font-size: 20px;
        padding: 10px;
        margin: 0px;
    }
`;