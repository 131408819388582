import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { HeaderSection } from '../styledComponents/Header';

function Header() {
  const { t } = useTranslation();
  const location = useLocation();
  let titleKey = '';

  switch (location.pathname) {
      case '/':
          titleKey = t('Home');
          break;
      case '/login':
          titleKey = t('Login');
          break;
      case '/profile':
          titleKey = t('Profile');
          break;
      case '/systemlist':
          titleKey = t('SystemList');
          break;
      case '/contacts':
          titleKey = t('Contact');
          break;
      case '/newfriend':
          titleKey = t('NewFriend');
          break;
      case '/helpdesk':
          titleKey = t('Helpdesk');
          break;
      case '/bill':
          titleKey = t('Bill');
          break;
      case '/wallet':
          titleKey = t('Wallet');
          break;
      case '/withdraw':
          titleKey = t('Withdraw');
          break;
      case '/setting':
          titleKey = t('Setting');
          break;
      case '/changepassword':
          titleKey = t('ChangePassword');
          break;
      case '/about':
          titleKey = t('About');
          break;
      case '/qrcode':
          titleKey = t('QrCode');
          break;
      case '/result':
          titleKey = t('D4Result');
          break;
      case '/payout':
          titleKey = t('PayoutTable');
          break;
      case '/angpaobonus':
          titleKey = t('FreeAngpaoEveryday');
          break;
      case '/addbank':
          titleKey = t('Addbank');
          break;
      case '/topup':
          titleKey = t('TopUp');
          break;
      case '/banklist':
          titleKey = t('BankList');
          break;
      case '/resetpass':
          titleKey = t('ResetPassword');
          break;
      case '/forgotid':
          titleKey = t('ForgotPassword');
          break;
      default:
          titleKey = t('Other');
  }

  return (
    <HeaderSection>
      <p className="title">{t(titleKey)}</p>
    </HeaderSection>
  );
}

export default Header;