import React, { useState, useEffect, Suspense, lazy } from 'react';
import i18n from './contexts/i18n';
import './static/style.css';
import './static/loading.css';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import { ToastContainer } from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css';
import Header from './components/Header';
import Footer from './components/Footer';
import PageHelmet from './components/PageHelmet';
import Loading from './components/Loading';

const Register = React.lazy(() => import('./pages/Register'));
const Login = React.lazy(() => import('./pages/Login'));
const Index = React.lazy(() => import('./pages/Index'));
const Profile = React.lazy(() => import('./pages/Profile'));
const SystemList = React.lazy(() => import('./pages/SystemList'));
const Contacts = React.lazy(() => import('./pages/Contacts'));
const NewFriend = React.lazy(() => import('./pages/NewFriend'));
const Bill = React.lazy(() => import('./pages/Bill'));
const QrCode = React.lazy(() => import('./pages/QrCode'));
const BankList = React.lazy(() => import('./pages/BankList'));
const AddBank = React.lazy(() => import('./pages/AddBank'));
const Setting = React.lazy(() => import('./pages/Setting'));
const GameRecord = React.lazy(() => import('./pages/GameRecord'));
const Wallet = React.lazy(() => import('./pages/Wallet'));
const Withdraw = React.lazy(() => import('./pages/Withdraw'));
const TopUp = React.lazy(() => import('./pages/TopUp'));
const ChangePassword = React.lazy(() => import('./pages/ChangePassword'));
const AngpaoBonus = React.lazy(() => import('./pages/AngpaoBonus'));
const HelpDesk = React.lazy(() => import('./pages/HelpDesk'));
const Result = React.lazy(() => import('./pages/Result'));
const Payout = React.lazy(() => import('./pages/Payout'));
const ForgotID = React.lazy(() => import('./pages/ForgotID'));
const About = React.lazy(() => import('./pages/About'));
const ResetPass = React.lazy(() => import('./pages/ResetPass'));

function AppContent() {
  let location = useLocation();
  const { isAuthenticated, setReferral, isAuthForReset } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const shouldShowHeaderFooter = !['/login', '/bet', '/register'].includes(location.pathname);

  // 获取推荐人信息并存储在上下文中
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const referral = urlParams.get('refferal');
    if (referral) {
      setReferral(referral);
    }
  }, [setReferral]);

  // 监听路由变化
  useEffect(() => {
    setIsLoading(true);

    // 假设页面加载完成
    setTimeout(() => setIsLoading(false), 1000); // 这里使用 setTimeout 来模拟页面加载过程

  }, [location]);

  return (
    <>
      {shouldShowHeaderFooter && <Header />}
      
      <PageHelmet /> {/* 在这里使用 PageHelmet 组件 */}

      {isLoading && <Loading />} {/* 在这里显示 Loading 组件 */}

      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<LoginRedirect isAuthenticated={isAuthenticated} PathShowing={<Login />} />} />
          <Route path="/profile" element={<UnLoginRedirect isAuthenticated={isAuthenticated} PathShowing={<Profile />} />} />
          <Route path="/systemlist" element={<SystemList />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/newfriend" element={<UnLoginRedirect isAuthenticated={isAuthenticated} PathShowing={<NewFriend />} />} />
          <Route path="/bill" element={<UnLoginRedirect isAuthenticated={isAuthenticated} PathShowing={<Bill />} />} />
          <Route path="/qrcode" element={<UnLoginRedirect isAuthenticated={isAuthenticated} PathShowing={<QrCode />} />} />
          <Route path="/banklist" element={<UnLoginRedirect isAuthenticated={isAuthenticated} PathShowing={<BankList />} />} />
          <Route path="/addbank" element={<UnLoginRedirect isAuthenticated={isAuthenticated} PathShowing={<AddBank />} />} />
          <Route path="/setting" element={<UnLoginRedirect isAuthenticated={isAuthenticated} PathShowing={<Setting />} />} />
          <Route path="/wallet" element={<UnLoginRedirect isAuthenticated={isAuthenticated} PathShowing={<Wallet />} />} />
          <Route path="/gamerecord" element={<UnLoginRedirect isAuthenticated={isAuthenticated} PathShowing={<GameRecord />} />} />
          <Route path="/withdraw" element={<UnLoginRedirect isAuthenticated={isAuthenticated} PathShowing={<Withdraw />} />} />
          <Route path="/topup" element={<UnLoginRedirect isAuthenticated={isAuthenticated} PathShowing={<TopUp />} />} />
          <Route path="/changepassword" element={<UnLoginRedirect isAuthenticated={isAuthenticated} PathShowing={<ChangePassword />} />} />
          <Route path="/angpaobonus" element={<AngpaoBonus />} />
          <Route path="/helpdesk" element={<HelpDesk />} />
          <Route path="/result" element={<Result />} />
          <Route path="/payout" element={<Payout />} />
          <Route path="/forgotid" element={<LoginRedirect isAuthenticated={isAuthenticated} PathShowing={<ForgotID />} />} />
          <Route path="/about" element={<About />} />
          <Route path="/resetpass" element={isAuthForReset ? <ResetPass /> : <Navigate to="/forgotid" replace />} />

          {/* 添加这行代码以处理不存在的路由 */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>

      {shouldShowHeaderFooter && <Footer />}
    </>
  );
}

function App() {
  const { isLanguage } = useAuth();

  useEffect(() => {
    i18n.changeLanguage(isLanguage);
  }, [isLanguage]); 

  return (
    <Router>
      <ToastContainer />
      <AppContent />
    </Router>
  );
}

function LoginRedirect({ isAuthenticated, PathShowing }) {
  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return PathShowing;
}

function UnLoginRedirect({ isAuthenticated, PathShowing }) {
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return PathShowing;
}

export default App;
