import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18n from './contexts/i18n'; // 修改路径到你的 i18n.js 文件
import { AuthProvider } from './contexts/AuthContext';

const WrapperContainer = ({ children }) => (
    <div className="wrapper-container" style={{
      maxWidth: '970px',
      margin: '0 auto',
      height: '100%',
      background: 'white'
    }}>
      {children}
    </div>
  );

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <WrapperContainer>
      <I18nextProvider i18n={i18n}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </I18nextProvider>
    </WrapperContainer>
);