import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FooterSection } from '../styledComponents/Footer';
import { useTranslation } from 'react-i18next';

function Footer() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleNavigate = (path) => {
        navigate(path);
    }
    
    return (
        <FooterSection style={{ zIndex: 1000 }}>
            <ul id="HomeNav">
                <li id="messageMenu" onClick={() => handleNavigate('/systemlist')}>
                    <div className="flex1" style={{ position: 'relative' }}>
                        <span className="redDot" style={{ display: 'none' }}></span>
                        <img src="https://asset.wkapi.cc//static/WINBOX/Agent/Navigation/wb_xitong.svg" alt="System Icon" className="tabIcon" />
                    </div>
                    <div className="textMin marginBottom2 lightGray">{t("System")}</div>
                </li>
                <li id="firendMenu" onClick={() => handleNavigate('/contacts')}>
                    <div className="flex1" style={{ position: 'relative' }}>
                        <span className="redDot" style={{ display: 'none' }}></span>
                        <img src="https://asset.wkapi.cc//static/WINBOX/Agent/Navigation/wb_tongxunlv.svg" alt="Contacts Icon" className="tabIcon" />
                    </div>
                    <div className="textMin marginBottom2 lightGray">{t("Contacts")}</div>
                </li>
                <li onClick={() => handleNavigate('/')} className="navCenter" style={{ background: 'url("https://asset.wkapi.cc//static/WINBOX/Agent/Navigation/round.png") 0px / 100% 60px' }}>
                    <div id="qrBox" className="qrBox" style={{ width: 55, height: 55, borderRadius: 27.5 }}>
                        <img src={`${process.env.PUBLIC_URL}/static/nav_logo.png`} alt="Logo" style={{ width: 55 }} />
                    </div>
                </li>
                <li id="gameMenu" onClick={() => handleNavigate('/bill')}>
                    <div className="flex1">
                        <img src="https://asset.wkapi.cc//static/WINBOX/Agent/Navigation/wb_Bjiaoyi.svg" alt="Transaction Icon" className="tabIcon" />
                    </div>
                    <div className="textMin marginBottom2 lightGray">{t("Transaction")}</div>
                </li>
                <li id="userMenu" onClick={() => handleNavigate('/profile')}>
                    <div className="flex1">
                        <img src="https://asset.wkapi.cc//static/WINBOX/Agent/Navigation/wb_wode.svg" alt="Me Icon" className="tabIcon" />
                    </div>
                    <div className="textMin marginBottom2 lightGray">{t("Me")}</div>
                </li>
            </ul>
        </FooterSection>
    );
}

export default Footer;