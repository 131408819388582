import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const routeDetails = {
    '/': { 
        title: 'GTA4D | Original 4D Results - Magnum 4D, Sports ToTo 4D Jackpot, DaMaCai 1+3D', 
        description: 'Check LIVE 4D results for Magnum, DaMaCai, and Sports TOTO. Instant updates, accurate draws, and jackpot news at your fingertips.' 
    },
    '/bill': { 
        title: 'GTA4D - Bill', 
        description: 'Manage your GTA4D billing information, view past transactions, and keep track of your betting expenditures and winnings.' 
    },
    '/profile': { 
        title: 'GTA4D - Profile', 
        description: 'Edit and personalize your GTA4D profile. Update your contact information, preferences, and manage your account settings.' 
    },
    '/setting': { 
        title: 'GTA4D - Setting', 
        description: 'Customize your GTA4D experience. Adjust your settings for notifications, privacy, and control your account security.' 
    },
    '/changepassword': { 
        title: 'GTA4D - Change Password', 
        description: 'Ensure the security of your GTA4D account by changing your password regularly. Easy and secure password update process.' 
    },
    '/about': { 
        title: 'GTA4D - About', 
        description: 'Learn more about GTA4D, our history, values, and why we are the leading 4D result provider for Magnum, TOTO, and DaMaCai.' 
    },
    '/wallet': { 
        title: 'GTA4D - Wallet', 
        description: 'Your GTA4D wallet - top up your balance, monitor your winnings, bonuses, and manage your funds with ease and security.' 
    },
    '/topup': { 
        title: 'GTA4D - Deposit', 
        description: 'Top up your GTA4D account swiftly. Our deposit page offers secure and straightforward options to ensure your account is ready for betting.' 
    },
    '/withdraw': { 
        title: 'GTA4D - Withdraw', 
        description: 'Withdraw your winnings from GTA4D quickly and safely. Check your balance and request fund transfers with our easy-to-use withdrawal system.' 
    },
    '/helpdesk': { 
        title: 'GTA4D - LiveChat Support', 
        description: 'Need assistance? Our LiveChat support team is here to help with any inquiries, account issues, or feedback 24/7.' 
    },
    '/contacts': { 
        title: 'GTA4D - Contact Us', 
        description: "Reach out to GTA4D for support, service inquiries, or partnerships. We're here to help and listen to our valued users."
    },
    '/newfriend': { 
        title: 'GTA4D - Refer Friends', 
        description: 'Invite friends to GTA4D and earn rewards. Share the excitement of 4D betting and benefit from our referral bonuses.' 
    },
    '/systemlist': { 
        title: 'GTA4D - System Betting List', 
        description: 'Explore various system betting options with GTA4D. Understand different betting systems and find the best fit for your strategy.' 
    },
    '/result': { 
        title: 'GTA4D - Malaysia 4D Lottery Result', 
        description: 'Access real-time Malaysia 4D lottery results. Stay up-to-date with the latest winning numbers for Magnum, DaMaCai, and Sports TOTO.' 
    },
    '/payout': { 
        title: 'GTA4D - Payout Details', 
        description: 'Get detailed information on GTA4D payouts. Learn about prize structures, how to claim your winnings, and the payout process for all games.' 
    },
};

const PageHelmet = () => {
  const location = useLocation();
  const currentRoute = routeDetails[location.pathname] || {
    title: 'GTA4D | Original 4D Results - Magnum 4D, Sports ToTo 4D Jackpot, DaMaCai 1+3D',
    description: 'Check LIVE 4D results for Magnum, DaMaCai, and Sports TOTO. Instant updates, accurate draws, and jackpot news at your fingertips.',
  };

  return (
    <Helmet>
      <title>{currentRoute.title}</title>
      <meta name="description" content={currentRoute.description} />
    </Helmet>
  );
};

export default PageHelmet;