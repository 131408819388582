import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translations: {
        Home: "Home",
        Login: "Login",
        Profile: "Profile",
        SystemList: "System List",
        Contact: "Contact",
        NewFriend: "New Friend",
        Helpdesk: "Helpdesk",
        Bill: "Bill",
        Wallet: "Wallet",
        Withdraw: "Withdraw",
        Setting: "Setting",
        TopUp: "TopUp",
        ChangePassword: "Change Password",
        About: "About",
        QrCode: "Qr Code",
        D4Result: "4D Result",
        PayoutTable:"Payout Table",
        FreeAngpaoEveryday:"Free Angpao Everyday",
        Addbank:"Add Bank",
        ResetPassword: "Reset Password",
        ForgotPassword: "Forgot Password",
        Other:"Other",
        System:"System",
        Contacts:"Contacts",
        Transaction:"Transaction",
        Me:"Me",
        AddBankTitle: "Please add your own bank account",
        AccountName: "Account Name",
        AccountNamePlaceholder: "Please enter real name",
        Bank: "Bank",
        BankSelect: "Please select bank",
        AccountNo: "Account No",
        AccountNoPlaceholder: "Please enter account No",
        BtnNext: "Next",
        ClaimNow: "Claim Now",
        AngpaoDes: "Claim your daily lucky red packets.",
        AngpaoDes1: "Notice:",
        AngpaoDes2: "1) If the betting does not exceed MYR5.00 after receiving the red envelope, the withdrawal limit is 30% of the total win.",
        AngpaoDes3: "2) After receiving the red envelope, if there is a deposit in GTA4D and the betting exceeds MYR5.00, there is no limit for withdrawal.",
        AngpaoDes4: "3) Share it with your relatives and friends to let more people know about GTA4D.",
        AngpaoDes5: "4) Red envelopes can be CLAIM every day",
        AngpaoDes6: "5) When receiving the red envelope, the wallet balance must be less than MYR0.50.",
        AngpaoDes7: "If you don't claim the red envelope and directly deposit and place a bet, there is no limit to cash withdrawal.",
        BillDes: "No data for the current day",
        BillDes1: "Please check the data for other days",
        OldPassword: "Old Password",
        OldPasswordHolder:"Please enter old password",
        NewPassword: "New Password",
        NewPasswordHolder: "Please enter new password",
        Confirm: "Confirm",
        ConfirmHolder: "Please confirm password",
        PasswordDesc: "1. The password is 6-15 digits, are contains letters and numbers, spaces are not supported",
        PasswordDesc1: "2. Password can not be same to old pass",
        OldPasswordIsRequired: "Old password is required",
        PasswordMinLength: "Password must be at least 6 characters",
        PasswordMaxLength: "Password must not exceed 15 characters",
        PasswordMismatch: "Old password does not match",
        NewPasswordIsRequired: "New password is required",
        ConfirmPasswordIsRequired: "Please confirm your new password",
        PasswordsMustMatch: "Passwords must match",
        ContactSearch: "Search",
        ContactNewFriends: "New Friends",
        ContactHelpdesk: "Live Chat",
        ForgotPassDes: "Please verify by OTP for password reset",
        ForgotPassHolder: "Please enter mobile no",
        Submit: "Submit",
        ForgotPassDes1 : "Didn't receive New Password?",
        ContactUs: "Contact Us",
        GameRecords: "Game Records",
        Bets: "Bets",
        Win: "Win",
        NoMore: "No More",
        Nodata: "No data",
        ClickToOpen: "Click To Open",
        EnterMsg: "Enter your message...",
        SendBtn: "Send",
        Login: "Login",
        Register: "Register",
        Balance: "Balance",
        OutStanding: "Out Standing",
        GuideAdd: "Guide-Add to home screen",
        EnterEmail: "Enter UID/Email",
        EnterPassword: "Enter password",
        ForgotPassword : "Forgot UID/Password",
        SignIn:"Sign In",
        RegisterUID: "Register new UID",
        LoginDesc :"Install this webapp on your iphone：tap ",
        LoginDesc1: "and then Add to homescreen.",
        RegisterDate: "Register Date",
        Details: "Details",
        Prize: "Prize",
        Big: "Big",
        Small: "Small",
        A4: "4A",
        Price: "Price",
        Prize1: "Prize 1",
        Prize2: "Prize 2",
        Prize3: "Prize 3",
        Starters4: "Starters 4",
        Consolation5: "Consolation 5",
        Prize6: "Prize 6",
        Withdaraw: "Withdaraw",
        Live: "Live",
        Display: "Display",
        Tools: "Tools",
        TopupWithdraw :"Top up / Withdraw",
        Order: "Order",
        More: "More",
        NoData: "No Data",
        SaveToAlbum: "Save To Album",
        ScanToTransfer: "Copy Referral Link",
        All: "All",
        Date: "Date",
        DrawType: "Draw Type",
        LoadingData: "Loading or no data available...",
        MobileEmail: "Mobile No/Email",
        Language: "Language",
        SignOut: "Sign Out",
        SystemListTitle: "Notice: Player web version login preparation network, please save it. Thank you",
        NoMore: "No More",
        TransferTo:"Transfer To",
        Amount: "Amount",
        MinTopUp: "Min top up amount",
        MaxTopup: "Single maximum amount",
        Receipt: "Receipt",
        ClickPicture: "Click to change picture",
        UploadPicture: "Please upload picture",
        Cancel: "Cancel",
        AccountBank: "Account Bank",
        AccountNumber: "Account Number",
        WalletInfo: "Wallet Info",
        BankTransfer: "Bank Transfer",
        NoBankAccount: "No Bank Account",
        AddBankAccount: "Add Bank Account",
        BankList:"Bank List",
        LogoutDesc: "Are you sure to log out ?",
        HelpDeskDesc: "Please log in to your account to continue using our online chat service.",
        NewAccount: "New Account",
        Referrerinfo: "Referrer info",
        RegisterInfo: "The registration information",
        RegisterUsername: "Please enter mobile no",
        RegisterPassword: "Please enter new password",
        RegisterPassword2: "Please confirm password",
        RegisterOTP: "Please enter OTP",
        RegisterOTPBtn: "Request OTP",
        RegisterOTPBtnSend: "Resend",
        RegisterDescription: "Didn't receive OTP?",
        RegisterPrefix: "Please select a phone prefix",
        RegisterPhoneNumber: "Please enter a valid phone number",
        RegisterMobileNumber: "Please enter your mobile number",
        Register6Characters: "Password must be at least 6 characters",
        RegisterPassword: "Please enter a new password",
        RegisterMatch: "Passwords must match",
        RegisterConfirmPassword: "Please confirm your password",
        RegisterMobileNo: "Please enter mobile no",
        LoginSuccess: "Login successful",
        LoginFailed: "Login failed",
        InvalidOTP: "Invalid OTP",
        RegisterSuccess: "Registration successful",
        RegisterFailed: "Registration failed",
        SendOTPDone: "OTP has been sent to your mobile number",
        SendOTPFailed: "Failed to send OTP",
        DepositRequestDone: "Deposit request successful",
        DepositRequestFailed: "Deposit request failed",
        TransferTo: "Transfer To",
        CopiedClipboard: "Copied",
        CopyFailed: "Copy failed",
        SelectBank: "Please Select A Bank",
        EnterTransferAmount: "Please Enter Your Transfer Amount",
        FileRequired: "A file is required",
        AmountIsRequired: "Amount is required",
        AmountExceedsBalance: "Amount can't exceed {{currentBalance}}",
        MaxTwoDecimalPlaces: "Amount should have a maximum of 2 decimal places",
        AmountNotBlank: "* Amount is required",
        WithdrawRequestDone: "Withdrawal request submitted successfully",
        WithdrawRequestFailed: "Withdrawal request failed",
        RequiredLogin: "Please Login First",
        ClaimAngpaoDone: "Angpaw claimed successfully",
        ClaimAngpaoFailed: "You can't claim this Angpaw",
        OpenGameFailed: "Unable to start betting, please contact customer service",
        Confirmation: "Confirmation",
        ConfirmationText :"Do you want to start the game?",
        Yes: "Yes",
        No: "No"
      }
    },
    zh: {
      translations: {
        Home: "首页",
        Login: "登录",
        Profile: "简介",
        SystemList: "系统列表",
        Contact: "联系方式",
        NewFriend: "新用户",
        Helpdesk: "在线客服",
        Bill: "账单",
        Wallet: "钱包",
        Withdraw: "取款",
        Setting: "设置",
        TopUp: "充值",
        ChangePassword: "更改密码",
        About: "关于我们",
        QrCode: "二维码",
        D4Result: "4D 成绩",
        PayoutTable:"赔率表",
        FreeAngpaoEveryday:"天天红包",
        Addbank:"绑定银行",
        ResetPassword: "重置密码",
        ForgotPassword: "忘记密码",
        Other:"其他",
        System:"系统",
        Contacts:"联系方式",
        Transaction:"交易",
        Me:"我",
        AddBankTitle: "请添加您自己的银行账户",
        AccountName: "账户名称",
        AccountNamePlaceholder: "请输入真实姓名",
        Bank: "银行",
        BankSelect: "请选择银行",
        AccountNo: "账户号码",
        AccountNoPlaceholder: "请输入账户号码",
        BtnNext: "下一步",
        ClaimNow: "立即领取",
        AngpaoDes: "领取您每天的幸运红包。",
        AngpaoDes1: "注意：",
        AngpaoDes2: "1）若领取红包后下注金额不超过 MYR5.00 ，提款限额为总奖金的30%。",
        AngpaoDes3: "2）领取红包后如果有在 GTA4D 充值并投注单据超过 MYR5.00，提现没有限制。",
        AngpaoDes4: "3）分享给您的亲朋好友，让更多人了解GTA4D。",
        AngpaoDes5: "4）红包每天都能领取。",
        AngpaoDes6: "5）领取红包时，钱包余额必须低于 MYR0.50",
        AngpaoDes7: "如果没有领取红包直接充值下注，提现没有限制。",
        BillDes: "当天没有数据",
        BillDes1: "请查看其他时间的数据",
        OldPassword: "旧密码",
        OldPasswordHolder: "请输入旧密码",
        NewPassword: "新密码",
        NewPasswordHolder: "请输入新密码",
        Confirm: "确认",
        ConfirmHolder: "请确认密码",
        PasswordDesc: "1. 密码为6-15位，包含字母和数字，不支持空格",
        PasswordDesc1: "2. 密码不能与旧密码相同",
        OldPasswordIsRequired: "需要输入旧密码",
        PasswordMinLength: "密码至少需要6个字符",
        PasswordMaxLength: "密码不能超过15个字符",
        PasswordMismatch: "旧密码不匹配",
        NewPasswordIsRequired: "需要输入新密码",
        ConfirmPasswordIsRequired: "请确认您的新密码",
        PasswordsMustMatch: "两次密码必须相同",
        ContactSearch: "搜索",
        ContactNewFriends: "下线",
        ContactHelpdesk: "在线客服",
        ForgotPassDes: "请使用OTP进行密码重置验证",
        ForgotPassHolder: "请输入手机号",
        Submit: "提交",
        ForgotPassDes1: "没有收到新密码？",
        ContactUs: "联系我们",
        GameRecords: "游戏记录",
        Bets: "投注",
        Win: "获胜",
        NoMore: "没有更多",
        Nodata: "无数据",
        ClickToOpen: "点击打开",
        EnterMsg: "输入您的信息...",
        SendBtn: "发送",
        Login: "登录",
        Register: "注册",
        Balance: "余额",
        OutStanding: "未开彩",
        GuideAdd: "指南-添加到主屏幕",
        EnterEmail: "输入UID/邮箱",
        EnterPassword: "输入密码",
        ForgotPassword: "忘记UID/密码？",
        SignIn:"登入",
        RegisterUID: "注册新的UID",
        LoginDesc: "在您的iPhone上安装此网络应用：点击",
        LoginDesc1: "然后选择添加到主屏幕。",
        RegisterDate: "注册日期",
        Details: "详细",
        Prize: "奖",
        Big: "大",
        Small: "小",
        A4: "单A",
        Price: "价钱",
        Prize1: "头奖",
        Prize2: "二奖",
        Prize3: "三奖",
        Starters4: "入围奖",
        Consolation5: "安慰奖",
        Prize6: "单F",
        Withdaraw: "取款",
        Live: "直播记录",
        Display: "展示",
        Tools: "工具",
        TopupWithdraw :"充值/提款",
        Order: "单据",
        More: "更多",
        NoData: "目前数据",
        SaveToAlbum: "保存到相册",
        ScanToTransfer: "复制推荐链接",
        All: "全部",
        Date: "日期",
        DrawType: "彩票类型",
        LoadingData: "正在加载可用数据...",
        MobileEmail: "手机号码/电子邮件",
        Language: "语言",
        SignOut: "登出",
        SystemListTitle: "通知：玩家网页版登录预备网，请各位保存起来 谢谢",
        NoMore: "没有更多",
        TransferTo:"转移至",
        Amount: "金额",
        MinTopUp: "最小充值金额",
        MaxTopup: "单笔最大金额",
        Receipt: "收据",
        ClickPicture: "点击更换图片",
        UploadPicture: "请上传图片",
        Cancel: "取消",
        AccountBank: "账户银行",
        AccountNumber: "户口号码",
        WalletInfo: "钱包信息",
        BankTransfer: "银行转帐",
        NoBankAccount: "无银行账户",
        AddBankAccount: "添加银行账户",
        BankList:"银行列表",
        LogoutDesc: "您确定要退出吗？",
        HelpDeskDesc: "请先登录您的账户，以便继续使用我们的在线聊天服务。",
        NewAccount: "新账户",
        Referrerinfo: "推荐人信息",
        RegisterInfo: "注册信息",
        RegisterUsername: "请输入手机号",
        RegisterPassword: "请输入新密码",
        RegisterPassword2: "请确认密码",
        RegisterOTP: "请输入验证码",
        RegisterOTPBtn: "获取验证码",
        RegisterOTPBtnSend: "重新发送",
        RegisterDescription: "没有收到验证码？",
        RegisterPrefix: "请选择一个手机前缀",
        RegisterPhoneNumber: "请输入有效的手机号码",
        RegisterMobileNumber: "请输入你的手机号码",
        Register6Characters: "密码必须至少6个字符",
        RegisterPassword: "请输入新密码",
        RegisterMatch: "密码必须匹配",
        RegisterConfirmPassword: "请确认你的密码",
        RegisterMobileNo: "请输入手机号",
        LoginSuccess: "登录成功",
        LoginFailed: "登录失败",
        InvalidOTP: "验证码无效",
        RegisterSuccess: "注册成功",
        RegisterFailed: "注册失败",
        SendOTPDone: "验证码已发送到您的手机",
        SendOTPFailed: "发送验证码失败",
        DepositRequestDone: "存款请求成功",
        DepositRequestFailed: "存款请求失败",
        TransferTo: "转账到",
        CopiedClipboard: "已复制",
        CopyFailed: "复制失败",
        SelectBank: "请选择银行",
        EnterTransferAmount: "请输入您的转账金额",
        FileRequired: "需要上传文件",
        AmountIsRequired: "金额为必填项",
        AmountExceedsBalance: "金额不能超过 {{currentBalance}}",
        MaxTwoDecimalPlaces: "金额应最多有2位小数",
        AmountNotBlank: "* 金额为必填项",
        WithdrawRequestDone: "提款申请提交成功",
        WithdrawRequestFailed: "提款申请失败",
        RequiredLogin: "请先登录您的账号",
        ClaimAngpaoDone: "红包领取成功",
        ClaimAngpaoFailed: "您没有权限领取这个红包",
        OpenGameFailed: "无法开始下注，请联系客服",
        Confirmation: "确认",
        ConfirmationText :"你想开始下注万字吗？",
        Yes: "是",
        No: "否"
      }
    },
    ms: {
      translations: {
        Home: "Laman",
        Login: "Log Masuk",
        Profile: "Pengenalan",
        SystemList: "Senarai Sistem",
        Contact: "Hubungi",
        NewFriend: "Pengguna baru",
        Helpdesk: "Helpdesk",
        Bill: "Bil",
        Wallet: "Dompet",
        Withdraw: "Cuci",
        TopUp: "Deposit",
        Setting: "Tetapan",
        ChangePassword: "Tukar Kata Laluan",
        About: "Mengenai Kami",
        QrCode: "Kod QR",
        D4Result: "Keputusan 4D",
        PayoutTable: "Jadual Bayaran",
        FreeAngpaoEveryday: "Angpao Hari-Hari",
        Addbank:"Ikat Bank",
        ResetPassword: "Tetapkan Semula Kata Laluan",
        ForgotPassword: "Lupa Kata Laluan",
        Other: "Lain-lain",
        System: "Sistem",
        Contacts: "Hubungan",
        Transaction: "Transaksi",
        Me: "Saya",
        AddBankTitle: "Sila tambah akaun bank anda sendiri",
        AccountName: "Nama Akaun",
        AccountNamePlaceholder: "Sila masukkan nama sebenar",
        Bank: "Bank",
        BankSelect: "Sila pilih bank",
        AccountNo: "No Akaun",
        AccountNoPlaceholder: "Sila masukkan no akaun",
        BtnNext: "Seterusnya",
        ClaimNow: "Tuntut Sekarang",
        AngpaoDes: "Tuntut Angpao Merah bertuah harian anda.",
        AngpaoDes1: "Notis:",
        AngpaoDes2: "1) Jika jumlah betting tidak melebihi MYR5.00 selepas menerima Angpao Merah, had pengeluaran ialah 30% daripada jumlah menang.",
        AngpaoDes3: "2) Selepas menerima Angpao Merah, jika ada topup dalam GTA4D dan betting melebihi MYR5.00, tiada had untuk pengeluaran.",
        AngpaoDes4: "3) Kongsi dengan rakan anda untuk memberitahu lebih ramai orang tentang GTA4D.",
        AngpaoDes5: "4) Angpao Merah boleh CLAIM setiap hari",
        AngpaoDes6: "5) Apabila CLAIM Angpao Merah, baki dompet mestilah kurang daripada MYR0.50",
        AngpaoDes7: "Jika anda tidak CLAIM Angpao Merah dan terus topup dan membuat betting, tiada had untuk pengeluaran tunai.",
        BillDes: "Tiada data untuk hari semasa",
        BillDes1: "Sila lihat data untuk hari lain",
        OldPassword: "Kata Laluan Lama",
        OldPasswordHolder: "Sila masukkan kata laluan lama",
        NewPassword: "Kata Laluan Baru",
        NewPasswordHolder: "Sila masukkan kata laluan baru",
        Confirm: "Sahkan",
        ConfirmHolder: "Sila sahkan kata laluan",
        PasswordDesc: "1. Kata laluan adalah 6-15 digit, mengandungi huruf dan nombor, ruang tidak disokong",
        PasswordDesc1: "2. Kata laluan tidak boleh sama dengan kata laluan lama",
        OldPasswordIsRequired: "Kata laluan lama diperlukan",
        PasswordMinLength: "Kata laluan mestilah sekurang-kurangnya 6 aksara",
        PasswordMaxLength: "Kata laluan tidak boleh melebihi 15 aksara",
        PasswordMismatch: "Kata laluan lama tidak sepadan",
        NewPasswordIsRequired: "Kata laluan baru diperlukan",
        ConfirmPasswordIsRequired: "Sila sahkan kata laluan anda yang baru",
        PasswordsMustMatch: "Kata laluan mesti sepadan",
        ContactSearch: "Search",
        ContactNewFriends: "Downline",
        ContactHelpdesk: "Live Chat",
        ForgotPassDes: "Sila sahkan dengan OTP untuk menetapkan semula kata laluan",
        ForgotPassHolder: "Sila masukkan nombor telefon bimbit",
        Submit: "Hantar",
        ForgotPassDes1: "Tidak menerima Kata Laluan baru?",
        ContactUs: "Hubungi Kami",
        GameRecords: "Rekod Permainan",
        Bets: "Pertaruhan",
        Win: "Menang",
        NoMore: "Tiada lagi",
        Nodata: "Tiada data",
        ClickToOpen: "Klik Buka",
        EnterMsg: "Masukkan mesej anda...",
        SendBtn: "Hantar",
        Login: "Log Masuk",
        Register: "Daftar",
        Balance: "Dompet",
        OutStanding: "Belum Hasil",
        GuideAdd: "Panduan-Tambah ke skrin utama",
        EnterEmail: "Masukkan UID/E-mel",
        EnterPassword: "Masukkan kata laluan",
        ForgotPassword: "Lupa UID/Kata laluan?",
        SignIn:"Log Masuk",
        RegisterUID: "Daftar UID baru",
        LoginDesc: "Pasang aplikasi web ini pada iPhone anda：ketuk",
        LoginDesc1: "kemudian Tambah ke skrin utama.",
        RegisterDate: "Tarikh Daftar",
        Details: "Butiran",
        Prize: "奖",
        Big: "大",
        Small: "小",
        A4: "单A",
        Price: "Harga",
        Prize1: "Prize 1",
        Prize2: "Prize 2",
        Prize3: "Prize 3",
        Starters4: "Starters 4",
        Consolation5: "Consolation 5",
        Prize6: "Prize 6",
        Withdaraw: "Cuci",
        Live: "Live",
        Display: "Paparan",
        Tools: "Alatan",
        TopupWithdraw :"Deposit / Pengeluaran",
        Order: "Dokumen",
        More: "Lagi",
        NoData: "Tiada Data",
        SaveToAlbum: "Simpan Ke Album",
        ScanToTransfer: "Salin Pautan Rujukan",
        All: "Semua",
        Date: "Tarikh",
        DrawType: "Jenis Loteri",
        LoadingData: "Memuatkan atau tiada data tersedia...",
        MobileEmail: "No/E-mel Mudah Alih",
        Language: "Bahasa",
        SignOut: "Log Keluar",
        SystemListTitle: "Notis: Rangkaian penyediaan log masuk versi web pemain, sila simpan. Terima kasih",
        NoMore: "Tiada Lagi",
        TransferTo:"Pindahkan Ke",
        Amount: "Jumlah",
        MinTopUp: "Jumlah tambah nilai min",
        MaxTopup: "Jumlah maksimum tunggal",
        Receipt: "Resit",
        ClickPicture: "Klik untuk menukar gambar",
        UploadPicture: "Sila muat naik gambar",
        Cancel: "Batal",
        AccountBank: "Bank Akaun",
        AccountNumber: "Nombor akaun",
        WalletInfo: "Maklumat Dompet",
        BankTransfer: "Pemindahan Bank",
        NoBankAccount: "Tiada Akaun Bank",
        AddBankAccount: "Tambah Akaun Bank",
        BankList:"Senarai Bank",
        LogoutDesc: "Adakah anda pasti untuk log keluar?",
        HelpDeskDesc: "Sila log masuk ke akaun anda untuk meneruskan menggunakan perkhidmatan sembang atas talian kami.",
        NewAccount: "Akaun Baru",
        Referrerinfo: "Maklumat Perujuk",
        RegisterInfo: "Maklumat Pendaftaran",
        RegisterUsername: "Sila masukkan nombor telefon bimbit",
        RegisterPassword: "Sila masukkan kata laluan baru",
        RegisterPassword2: "Sila sahkan kata laluan",
        RegisterOTP: "Sila masukkan OTP",
        RegisterOTPBtn: "Minta OTP",
        RegisterOTPBtnSend: "Hantar Semula",
        RegisterDescription: "Tidak terima OTP?",
        RegisterPrefix: "Sila pilih kod telefon",
        RegisterPhoneNumber: "Sila masukkan nombor telefon yang sah",
        RegisterMobileNumber: "Sila masukkan nombor telefon bimbit anda",
        Register6Characters: "Kata laluan mestilah sekurang-kurangnya 6 aksara",
        RegisterPassword: "Sila masukkan kata laluan baru",
        RegisterMatch: "Kata laluan mesti sepadan",
        RegisterConfirmPassword: "Sila sahkan kata laluan anda",
        RegisterMobileNo: "Sila masukkan nombor telefon",
        LoginSuccess: "Log masuk berjaya",
        LoginFailed: "Log masuk gagal",
        InvalidOTP: "OTP tidak sah",
        RegisterSuccess: "Pendaftaran berjaya",
        RegisterFailed: "Pendaftaran gagal",
        SendOTPDone: "OTP telah dihantar ke nombor telefon anda",
        SendOTPFailed: "Gagal menghantar OTP",
        DepositRequestDone: "Permintaan deposit berjaya",
        DepositRequestFailed: "Permintaan deposit gagal",
        TransferTo: "Pemindahan Kepada",
        CopiedClipboard: "Disalin",
        CopyFailed: "Gagal menyalin",
        SelectBank: "Sila Pilih Bank",
        EnterTransferAmount: "Sila Masukkan Jumlah Pemindahan Anda",
        FileRequired: "Fail diperlukan",
        AmountIsRequired: "Jumlah diperlukan",
        AmountExceedsBalance: "Jumlah tidak boleh melebihi {{currentBalance}}",
        MaxTwoDecimalPlaces: "Jumlah seharusnya mempunyai maksimum 2 tempat perpuluhan",
        AmountNotBlank: "* Jumlah diperlukan",
        WithdrawRequestDone: "Permintaan pengeluaran berjaya dihantar",
        WithdrawRequestFailed: "Permintaan pengeluaran gagal",
        RequiredLogin: "Sila log masuk terlebih dahulu",
        ClaimAngpaoDone: "Angpao berjaya dituntut",
        ClaimAngpaoFailed: "Anda tidak boleh menuntut angpao ini",
        OpenGameFailed: "Tidak dapat memulakan pertaruhan, sila hubungi perkhidmatan pelanggan",
        Confirmation: "Pengesahan",
        ConfirmationText :"Adakah anda mahu memulakan permainan?",
        Yes: "Ya",
        No: "Tidak"
      }
    }
  },
  lng: "en",
  fallbackLng: "en",
  ns: ["translations"],
  defaultNS: "translations"
});

export default i18n;
