import styled from 'styled-components';

export const FooterSection = styled.div`
    width: 100%;
    position: sticky;
    max-width: 970px;
    height: 55px;
    
    div {
        box-sizing: border-box;
    }
    
    body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, dl, dt, dd, ul, ol, li, pre, form, fieldset, legend, button, input, textarea, th, td {
        margin: 0;
        padding: 0;
    }

    ul, li {
        list-style: none;
    }
    ol, ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .qrBox {
        width: 55px;
        height: 55px;
        border-radius: 27.5px;
        margin-top: -25px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .marginBottom2 {
        margin-bottom: 2px
    }
    .textMin {
        font-size: 12px
    }
    .lightGray {
        color: #8d8f99
    }
    .tabIcon {
        width: 26px;
        height: 26px
    }

    #HomeNav {
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        z-index: 100;
    }

    @supports (bottom: constant(safe-area-inset-bottom)) or (bottom: env(safe-area-inset-bottom)){
        #HomeNav{
            padding-bottom: calc(constant(safe-area-inset-bottom));
            padding-bottom: calc(env(safe-area-inset-bottom));
            transition: all 0.2s linear;
            position: relative;
        }
        #HomeNav::after{
            content: "";
            display: block;
            height: calc(constant(safe-area-inset-bottom));
            height: calc(env(safe-area-inset-bottom));
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            background: #fff;
        }
    }
    
    .safe-area-inset-bottom{
        width: 100%;
        height: calc(constant(safe-area-inset-bottom));
        height: calc(env(safe-area-inset-bottom));
    }
    
    #HomeNav li:nth-child(1),#HomeNav li:nth-child(2),#HomeNav li:nth-child(4),#HomeNav li:nth-child(5) {
        flex: 1;
        height: 47.7px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-size: 100% 115%;
        background-position: 0 -8px;
        background: #fff;
        box-shadow: 7px -3px 10px 0px rgba(77,77,77,.1)
    }
    
    #HomeNav li>div:first-child {
        display: flex;
        align-items: center;
        justify-content: center
    }
    
    #HomeNav li:nth-child(3).navCenter {
        z-index: 100;
        width: 88px;
        height: 57.7px;
        display: flex;
        justify-content: center;
    }
    
    
`;