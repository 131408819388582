import React, { createContext, useState, useContext, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        const storedAuth = localStorage.getItem('isAuthenticated');
        return storedAuth ? JSON.parse(storedAuth) : false;
    });
    const [isLanguage, setIsLanguage] = useState(() => {
        const storedLanguage = localStorage.getItem('isLanguage');
        return storedLanguage || 'en';
    });
    const [userData, setUserData] = useState(null);
    const [userPassword, setUserPassword] = useState(null);
    const [userLoginID, setLoginID] = useState(null);
    const [userRefferal, setReferral] = useState(null);
    const [adminWhatsapp, setAdminWhatsapp] = useState(null);
    const [resetUser, setResetUser] = useState(null);
    const [isAuthForReset, setIsAuthForReset] = useState(false);

    // 在组件挂载时，检查localStorage中的身份验证数据
    useEffect(() => {
        const storedLanguage = localStorage.getItem('isLanguage');
        const storedUserData = localStorage.getItem('userData');
        const storedUserPassword = localStorage.getItem('userPassword');
        const storedUserLoginID = localStorage.getItem('userLoginID');
        const storedUserRefferal = localStorage.getItem('userRefferal');
        const storedAdminWhatsapp = localStorage.getItem('adminWhatsapp');

        if (storedLanguage) {
            setIsLanguage(storedLanguage);
        }

        if (storedUserData) {
            setUserData(JSON.parse(storedUserData));
        }
        if (storedUserPassword) {
            setUserPassword(JSON.parse(storedUserPassword));
        }
        if (storedUserLoginID) {
            setLoginID(JSON.parse(storedUserLoginID));
        }
        if (storedUserRefferal) {
            setReferral(JSON.parse(storedUserRefferal));
        }
        if (storedAdminWhatsapp) {
            setAdminWhatsapp(JSON.parse(storedAdminWhatsapp));
        }
    }, []);

    const handleSetIsAuthenticated = (auth) => {
        setIsAuthenticated(auth);
        localStorage.setItem('isAuthenticated', JSON.stringify(auth));
    };

    const handleSetIsLanguage = (lang) => {
        setIsLanguage(lang);
        localStorage.setItem('isLanguage', lang);
    };

    const handleSetUserData = (data) => {
        setUserData(data);
        localStorage.setItem('userData', JSON.stringify(data));
    };

    const handleSetUserPassword = (data) => {
        setUserPassword(data);
        localStorage.setItem('userPassword', JSON.stringify(data));
    };

    const handleSetLoginID = (data) => {
        setLoginID(data);
        localStorage.setItem('userLoginID', JSON.stringify(data));
    };

    const handleSetReferral = (data) => {
        setReferral(data);
        localStorage.setItem('userRefferal', JSON.stringify(data));
    };

    const handleSetAdminWhatsapp = (data) => {
        setAdminWhatsapp(data);
        localStorage.setItem('adminWhatsapp', JSON.stringify(data));
    };

    const handleSetResetUser = (data) => {
        setResetUser(data);
    };

    const handleSetIsAuthForReset = (data) => {
        setIsAuthForReset(data);
    };

    return (
        <AuthContext.Provider value={{ 
            isAuthenticated, 
            isLanguage,
            userData, 
            userPassword,
            userLoginID,
            userRefferal,
            adminWhatsapp,
            resetUser,
            isAuthForReset,
            setIsAuthenticated: handleSetIsAuthenticated,
            setIsLanguage: handleSetIsLanguage,
            setUserData: handleSetUserData,
            setUserPassword: handleSetUserPassword,
            setLoginID: handleSetLoginID,
            setReferral: handleSetReferral,
            setAdminWhatsapp: handleSetAdminWhatsapp,
            setResetUser: handleSetResetUser,
            setIsAuthForReset: handleSetIsAuthForReset
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
